export default {
  loadingCustomViews: undefined,
  customViews       : new Array(),
  addingCustomView  : undefined,
  customViewAdded   : undefined,
  customViewAddError: undefined,
  updatingCustomView: {
    name     : false,
    creatorId: false
  },
  customViewUpdated: {
    name     : false,
    creatorId: false
  },
  customViewUpdateError: {
    name     : false,
    creatorId: false
  }
}