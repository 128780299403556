export default {
  setLoadingCustomViews: (state, loadingCustomViews) => {
    state.loadingCustomViews = loadingCustomViews
  },
  setAddingCustomView: (state, addingCustomView) => {
    state.addingCustomView = addingCustomView
  },
  setCustomViewAdded: (state, customViewAdded) => {
    state.customViewAdded = customViewAdded
  },
  setCustomViews: (state, customViews) => {
    state.customViews = customViews
  },
  setCustomViewAddError: (state, customViewAddError) => {
    state.customViewAddError = customViewAddError
  },
  updateCustomViews: (state, customViews) => {
    for (const customView of customViews) {
      const index = state.customViews.findIndex(stateCustomView => stateCustomView?.id === customView.id)
      if (index >= 0) {
        state.customViews.splice(index, 1, { ...state.customViews[index], ...customView })
      } else {
        state.customViews.push(customView)
      }
    }
  },
  setUpdatingCustomView: (state, properties) => {
    for (const property of properties) {
      state.updatingCustomView[property] = true
    }
  },
  resetUpdatingCustomView: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingCustomView[property] = false
      }
    } else {
      Object.keys(state.updatingCustomView).forEach(property => state.updatingCustomView[property] = false)
    }
  },
  setCustomViewUpdated: (state, properties) => {
    for (const property of properties) {
      state.customViewUpdated[property] = true
      setTimeout(() => {
        state.customViewUpdated[property] = false
      }, 5000)
    }
  },
  resetCustomViewUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.customViewUpdated[property] = false
      }
    } else {
      Object.keys(state.customViewUpdated).forEach(property => state.customViewUpdated[property] = false)
    }
  },
  setCustomViewUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.customViewUpdateError[property] = error
    }
  },
  resetCustomViewUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.customViewUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.customViewUpdateError).forEach(property => state.customViewUpdateError[property] = undefined)
    }
  }
}